"use client";

import React, { useEffect } from "react";
import {
  setIsMobileDevice,
  setIsIOS,
  getLocaleFromPathname,
  getUrlWithLocale,
} from "../../../lib/utils/constants/PagesConstants";
import { usePathname, useRouter } from "next/navigation";
import { getWebsiteEnvironment } from "../../../lib/utils/constants/EnvironmentConstants";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { BHARATTECH_TRIUMPH_PROGRAM_URL } from "../../../lib/utils/constants/GlobalConstants";
import Analytics from "../../../lib/analytics/Analytics";
import { getGDCPageEvents } from "../../../lib/analytics/AnalyticsEvents";

const PageInitialise = () => {
  const router: AppRouterInstance = useRouter();
  const pathName = usePathname();

  useEffect((): void => {
    if (pathName === `/${BHARATTECH_TRIUMPH_PROGRAM_URL}`) {
      Analytics.getInstance().logEvent(getGDCPageEvents("PageView"));
    }

    setIsMobileDevice();
    setIsIOS();
  }, []);

  useEffect(() => {
    const segmentsArray = pathName!
      .split("/")
      .filter((segment) => segment !== "");
    if (segmentsArray.includes(getWebsiteEnvironment().defaultLocale)) {
      router.replace(`/${segmentsArray.slice(1).join("/")}`, undefined);
    }
  }, [pathName]);

  return <></>;
};

export default PageInitialise;
